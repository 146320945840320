import React from "react";
// import { render } from "react-dom";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import { MapboxProvider } from "@origin-dot/components";

import { App } from "./components/App";
import { AuthProvider } from "./components/auth/AuthProvider";
import { auth } from "./firebase";

import "./index.css";
import "mapbox-gl/dist/mapbox-gl.css";

import { FirestoreProfileProvider } from "./components/FirestoreProfileProvider";

// eslint-disable-next-line import/order
import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: "identified_only",
  capture_pageview: false, // Done in App.js
};

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <PostHogProvider apiKey={process.env.POSTHOG_KEY} options={options}>
    <AuthProvider auth={auth}>
      <Router>
        <FirestoreProfileProvider>
          <MapboxProvider
            accessToken={process.env.MAPBOX_ACCESS_TOKEN}
            hideControls
          >
            <App />
          </MapboxProvider>
        </FirestoreProfileProvider>
      </Router>
    </AuthProvider>
  </PostHogProvider>,
);
