import { faArrowUp as sendIcon } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addDoc,
  deleteDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import posthog from "posthog-js";
import React, { useEffect, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { PendingChangesPrompt } from "../PendingChangesPrompt";
import { useAuth } from "../auth/useAuth";

const ChatInput = ({
  messagesCollection,
  className,
  editedMessage,
  setEditedMessage,
  userChatInputRef,
  userChatInputText,
  setUserChatInputText,
  additionalButton,
}) => {
  const auth = useAuth();
  const formRef = useRef();
  const submitButtonRef = useRef(null);

  useEffect(() => {
    if (editedMessage) {
      const message = editedMessage.data();
      setUserChatInputText(message.formattedText);
    }
  }, [editedMessage, setUserChatInputText]);

  useEffect(() => {
    const handleKeypress = (event) => {
      if (event.key === "`") {
        const { target } = event;
        if (!["input", "textarea"].includes(target.tagName.toLowerCase())) {
          event.preventDefault();
          userChatInputRef.current.focus();
        }
      }
    };
    document.addEventListener("keypress", handleKeypress);
    return () => {
      document.removeEventListener("keypress", handleKeypress);
    };
  }, [userChatInputRef]);

  const sendMessage = async () => {
    if (userChatInputText.trim() === "") return;
    await addDoc(messagesCollection, {
      time: { date: serverTimestamp() },
      userId: auth.userId,
      formattedText: userChatInputText,
      userIsAdmin: auth.claims?.admin,
    });
    setUserChatInputText("");
  };

  const updateMessage = async () => {
    if (userChatInputText.trim() === "") {
      // eslint-disable-next-line no-alert, no-restricted-globals
      if (!confirm("Are you sure that you want to delete this message?")) {
        return;
      }
      await deleteDoc(editedMessage.ref);
      return;
    }
    await updateDoc(editedMessage.ref, { formattedText: userChatInputText });
    setUserChatInputText("");
    setEditedMessage(null);
  };

  const handleMessage = async (event) => {
    event.preventDefault();
    if (userChatInputText.toLowerCase().includes("%change_me%")) {
      // eslint-disable-next-line no-alert
      alert("Change all sections of text with %CHANGE_ME%");
      return;
    }
    if (editedMessage) {
      await updateMessage();
    } else {
      await sendMessage();
      if (event && event.key === "Enter") {
        posthog.capture("send_chat_using_enter");
      } else {
        posthog.capture("send_chat_clicking_button");
      }
    }
  };

  return (
    <form
      ref={formRef}
      className={`${className || ""} relative`}
      onSubmit={(event) => handleMessage(event)}
    >
      <TextareaAutosize
        id="textarea"
        ref={userChatInputRef}
        className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-sm font-medium rounded-t-md py-3
        pl-2 pr-12 block w-full appearance-none leading-normal ds-input resize-none"
        placeholder="Message..."
        value={userChatInputText}
        onChange={(event) => setUserChatInputText(event.target.value)}
        onBlur={(event) => {
          if (event.relatedTarget !== submitButtonRef.current) {
            if (editedMessage) {
              setEditedMessage(null);
              setUserChatInputText("");
            }
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !event.shiftKey) {
            handleMessage(event);
          }
          if (event.key === "Escape") {
            if (editedMessage) {
              event.preventDefault();
              setEditedMessage(null);
              setUserChatInputText("");
            }
          }
        }}
      />
      {!userChatInputText && additionalButton}
      <button
        type="submit"
        ref={submitButtonRef}
        className="bg-coloredButtonForegroundColor absolute rounded w-10 px-2 h-10 ph-no-capture"
        style={{ bottom: "0.25rem", right: "0.25rem" }}
      >
        <FontAwesomeIcon icon={sendIcon} className="text-chatSentTextColor" />
      </button>

      <PendingChangesPrompt show={userChatInputText} />
    </form>
  );
};
export default ChatInput;
