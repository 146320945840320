import React, { useEffect, useMemo, useState } from "react";

import * as eccrypto from "@toruslabs/eccrypto";
import { startCase } from "lodash";

import { faBarcode as documentNumberIcon } from "@fortawesome/free-solid-svg-icons/faBarcode";
import { faCalendar as expiryIcon } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faEye as viewIcon } from "@fortawesome/free-solid-svg-icons/faEye";
import { faIdCard as documentIcon } from "@fortawesome/free-solid-svg-icons/faIdCard";
import { faInfo as infoIcon } from "@fortawesome/free-solid-svg-icons/faInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  GoogleAuthProvider,
  getAuth,
  reauthenticateWithRedirect,
} from "firebase/auth";
import { collection, doc, query } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { Link, useLocation } from "react-router-dom";
import { firestore } from "../../firebase";
import { useAuth } from "../auth/useAuth";

export const UserTravelDocument = ({
  travelDocument: { id, encryptedData, documentNumberSuffix, expiryDate, type },
  userId,
}) => {
  const { hash, pathname } = useLocation();
  const {
    claims: { privateKey, auth_time: authTime },
  } = useAuth();
  const [decryptedInfo, setDecryptedInfo] = useState(null);
  const [isSecure, setIsSecure] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      setIsSecure(false);

      if (hash === `#${id}`) {
        const currentTime = Math.floor(new Date().getTime() / 1000);
        if (currentTime - authTime > 60) {
          const auth = getAuth();
          const provider = new GoogleAuthProvider();
          await reauthenticateWithRedirect(auth.currentUser, provider);
        }

        setIsSecure(true);
      }
    };

    checkAuth(hash, authTime);
  }, [authTime, hash, id]);

  useEffect(() => {
    setDecryptedInfo(null);

    const decryptDocument = async () => {
      if (hash === `#${id}` && isSecure) {
        try {
          const decodedPrivateKey = Buffer.from(privateKey, "hex");
          const dataJson = JSON.parse(encryptedData);
          const ecies = Object.fromEntries(
            dataJson.map(([key, value]) => [key, Buffer.from(value, "hex")]),
          );
          const decrypted = await eccrypto.decrypt(decodedPrivateKey, ecies);
          setDecryptedInfo(JSON.parse(decrypted.toString()));
        } catch (error) {
          console.error(error);
        }
      }
    };

    decryptDocument();
  }, [hash, encryptedData, id, isSecure, privateKey]);

  return (
    <div className="my-2 px-2 py-1 border border-dividerColor rounded">
      <div className="text-sm mt-3 flex items-center">
        <FontAwesomeIcon icon={documentIcon} fixedWidth />
        <div className="flex-grow mx-1">Document Type:</div>
        <div className="font-bold text-right">{type}</div>
      </div>
      <div className="text-sm mt-3 flex items-center">
        <FontAwesomeIcon icon={documentNumberIcon} fixedWidth />
        <div className="flex-grow mx-1">Document Number:</div>
        {decryptedInfo && privateKey ? (
          <div className="font-bold text-right relative travel-document">
            <span className="travel-document-number">
              {" "}
              {decryptedInfo.documentNumber}{" "}
            </span>
          </div>
        ) : (
          <Link
            to={hash === `#${id}` ? pathname : `#${id}`}
            className={`font-bold text-right relative travel-document ${
              !decryptedInfo && privateKey && "hover"
            }`}
          >
            <span className="travel-document-number">
              {decryptedInfo?.documentNumber ||
                `*******${documentNumberSuffix}`}
            </span>
            <FontAwesomeIcon
              className="travel-document-view-icon hidden absolute ml-auto mr-auto left-0 right-0"
              icon={viewIcon}
              fixedWidth
            />
          </Link>
        )}
      </div>
      <div>
        {Object.keys(decryptedInfo || {})
          .filter((key) => key !== "documentNumber")
          .map((key) => {
            const value = decryptedInfo[key];
            return (
              <div className="text-sm mt-3 flex items-center" key={key}>
                <FontAwesomeIcon icon={infoIcon} fixedWidth />
                <div className="flex-grow mx-1">{startCase(key)}:</div>
                <div className="font-bold text-right relative travel-document">
                  <span className="travel-document-number">{value}</span>
                  <FontAwesomeIcon
                    className="travel-document-view-icon hidden absolute ml-auto mr-auto left-0 right-0"
                    icon={viewIcon}
                    fixedWidth
                  />
                </div>
              </div>
            );
          })}
      </div>
      <div className="text-sm mt-3 flex items-center">
        <FontAwesomeIcon icon={expiryIcon} fixedWidth />
        <div className="flex-grow mx-1">Expiry Date:</div>
        <div className="font-bold text-right">{expiryDate}</div>
      </div>
      <div className="text-sm mt-3">
        <a
          className="underline cursor-pointer"
          href={`${process.env.PASSPORT_URI}/documents/${id}?userid=${userId}`}
          target="_blank"
          rel="noreferrer"
        >
          View in Passport App
        </a>
      </div>
    </div>
  );
};

export const UserTravelDocuments = ({ userId }) => {
  const travelDocumentsQuery = useMemo(
    () => query(collection(doc(firestore, "users", userId), "travelDocuments")),
    [userId],
  );

  const [travelDocumentsSnapshot] = useCollection(travelDocumentsQuery);

  const travelDocumentsData = travelDocumentsSnapshot?.docs?.length;

  let travelDocuments;

  if (travelDocumentsData) {
    travelDocuments = travelDocumentsSnapshot.docs.map((doc) => {
      const { id } = doc;
      const { data, documentNumberSuffix, expiryDate, type } = doc.data();

      return {
        id,
        expiryDate,
        type,
        documentNumberSuffix,
        encryptedData: data,
      };
    });
  }
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <>
      {travelDocuments && travelDocuments.length > 0 && (
        <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow">
          <button
            className="text-lg font-semibold"
            onClick={() => setIsCollapsed(!isCollapsed)}
            style={{ textAlign: "left" }}
          >
            <h2>Travel Documents</h2>
          </button>
          {!isCollapsed &&
            travelDocuments?.map((travelDocument) => (
              <UserTravelDocument
                travelDocument={travelDocument}
                key={travelDocument.id}
                userId={userId}
              />
            ))}
        </div>
      )}
    </>
  );
};
