import { ProfileProvider } from "@origin-dot/components";
import { doc, onSnapshot } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import React from "react";
import { firestore, storage } from "../firebase";

const downloadUrlCache = {};

const resolveImageUrl = async (path) => {
  if (path.match(/^https?:\/\//i)) return path;

  if (!downloadUrlCache[path]) {
    const urlRef = ref(storage, path);
    downloadUrlCache[path] = await getDownloadURL(urlRef);
  }
  return downloadUrlCache[path];
};

export const FirestoreProfileProvider = ({ children }) => {
  const repository = (() => {
    const profiles = {};
    return {
      get: (userId) => {
        return profiles[userId];
      },
      onUpdate: (userId, setProfile) => {
        if (!userId || typeof userId !== "string" || userId.includes("/")) {
          return () => {};
        }

        if (profiles[userId]) setProfile(profiles[userId]);

        try {
          return onSnapshot(
            doc(firestore, "profiles", userId),
            async (docSnapshot) => {
              const profile = docSnapshot.exists() ? docSnapshot.data() : {};
              if (profile.picture)
                profile.picture = await resolveImageUrl(profile.picture);
              profiles[userId] = profile;
              setProfile(profile);
            },
            (error) => {
              console.error("Error in onSnapshot:", error);
            },
          );
        } catch (error) {
          console.error("Error setting up onSnapshot:", error);
          return () => {}; // Return a no-op function in case of error
        }
      },
    };
  })();

  return <ProfileProvider repository={repository}>{children}</ProfileProvider>;
};
