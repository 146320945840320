import React, { useMemo, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { Avatar, useProfile } from "@origin-dot/components";
import { Link } from "react-router-dom";
import { firestore } from "../firebase";

import { ColumnTitle } from "./ColumnTitle";
import { Layout, LayoutColumn } from "./Layout";
import { PageTitle } from "./PageTitle";
import { useAuth } from "./auth/useAuth";

import {
  collectionGroup,
  deleteDoc,
  doc,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getLocalTime } from "../helpers/time";
import { ActionButton } from "./ActionButton";

const PRE_TRIP_ON_TRIP_DAY_BUFFER = 7;
const POST_TRIP_ON_TRIP_DAY_BUFFER = 2;

const OpenMessageComponent = ({ openMessageMetadata }) => {
  const {
    userId,
    messageCount,
    displayMessageContent,
    oldestMessageTimestamp,
    newestMessageTimestamp,
  } = openMessageMetadata;
  const profile = useProfile(userId);

  const dismissOpenMessage = async () => {
    if (
      // eslint-disable-next-line no-restricted-globals,no-alert
      !confirm(
        `Are you sure ${
          profile?.displayName || "user"
        }'s chat will no longer show as "open" unless future messages are received. Please confirm that a response is not needed.`,
      )
    )
      return;
    const reference = doc(
      firestore,
      `/users/${userId}/openMessageMetadata/current`,
    );
    await deleteDoc(reference);
  };
  return profile ? (
    <div className="flex flex-col text-xs items-stretch rounded bg-cardBackgroundColor border border-searchBorderColor w-full p-3">
      <div className="flex items-center">
        <Link to={`/users/${userId}`}>
          <Avatar userId={userId} size={40} />
        </Link>
        <Link to={`/users/${userId}`}>
          <div className="ml-4 text-left">
            <div className="flex">
              <div className="font-bold text-base pr-3">
                {profile.displayName}
              </div>
              <div className="font-light mt-auto text-xs">
                Oldest:{" "}
                {getLocalTime({
                  date: oldestMessageTimestamp.toDate(),
                }).fromNow()}{" "}
                - Newest:{" "}
                {getLocalTime({
                  date: newestMessageTimestamp.toDate(),
                }).fromNow()}
              </div>
            </div>
            <div className="flex">
              {messageCount > 1 && (
                <div className="font-normal">
                  {messageCount} new chat messages • Latest:&nbsp;
                </div>
              )}
              <div
                className={`font-normal ${messageCount > 1 ? "italic" : ""}`}
              >
                {displayMessageContent}
              </div>
            </div>
          </div>
        </Link>
        <div className="ml-auto">
          <ActionButton
            className="bg-cardBackgroundColor"
            style={{ color: "#E85F5F" }}
            onAction={dismissOpenMessage}
          >
            X No response needed
          </ActionButton>
        </div>
      </div>
    </div>
  ) : null;
};

export const AllOpenMessagesPage = () => {
  const reference = useMemo(
    () =>
      query(
        collectionGroup(firestore, "openMessageMetadata"),
        where("isHidden", "==", false),
        orderBy("oldestMessageTimestamp", "asc"),
        limit(100),
      ),
    [],
  );
  const [openMessages] = useCollectionData(reference, { idField: "id" });

  return (
    <div className="flex-grow p-3 m-3 bg-cardBackgroundColor overflow-y-auto grid content-start gap-2 shadow rounded">
      {openMessages && openMessages.length > 0 ? (
        openMessages.map((openMessage) => (
          <OpenMessageComponent
            key={openMessage.userId}
            openMessageMetadata={openMessage}
          />
        ))
      ) : (
        <div> No Open Chats!</div>
      )}
    </div>
  );
};

export const TravelingOpenMessagesPage = () => {
  const now = new Date();
  const startDateBeforeOrEqualTo = new Date(now);
  startDateBeforeOrEqualTo.setDate(
    startDateBeforeOrEqualTo.getDate() + PRE_TRIP_ON_TRIP_DAY_BUFFER,
  );
  const startDateBeforeOrEqualToDateString = startDateBeforeOrEqualTo
    .toISOString()
    .split("T")[0];
  const endDateAfterOrEqualTo = new Date(now);
  endDateAfterOrEqualTo.setDate(
    endDateAfterOrEqualTo.getDate() - POST_TRIP_ON_TRIP_DAY_BUFFER,
  );
  const endDateAfterOrEqualToDateString = endDateAfterOrEqualTo
    .toISOString()
    .split("T")[0];
  const futureTripsReference = useMemo(
    () =>
      query(
        collectionGroup(firestore, "trips"),
        where("status", "==", "BOOKED"),
        where("endDate", ">=", endDateAfterOrEqualToDateString),
      ),
    [endDateAfterOrEqualToDateString],
  );

  const [futureTrips] = useCollectionData(futureTripsReference);
  const usersOnTrip =
    futureTrips
      ?.filter((doc) => doc.startDate <= startDateBeforeOrEqualToDateString)
      .reduce((usersOnTripAcc, futureTrip) => {
        usersOnTripAcc.push(...futureTrip.users);
        return usersOnTripAcc;
      }, []) || [];

  const reference = useMemo(
    () =>
      query(
        collectionGroup(firestore, "openMessageMetadata"),
        where("isHidden", "==", false),
        orderBy("oldestMessageTimestamp", "asc"),
        limit(100),
      ),
    [],
  );
  const [openMessages] = useCollectionData(reference, { idField: "id" });
  const openMessagesOnTrip =
    openMessages?.filter((openMessage) =>
      usersOnTrip.includes(openMessage.userId),
    ) || [];

  return (
    <div>
      <div className="flex-grow p-3 m-3 bg-cardBackgroundColor overflow-y-auto grid content-start gap-2 shadow rounded">
        <p>
          Users that are 7 days out from a trip, on trip or just coming back
          from a trip
        </p>
        {openMessagesOnTrip.length > 0 ? (
          openMessagesOnTrip.map((openMessage) => (
            <OpenMessageComponent
              key={openMessage.userId}
              openMessageMetadata={openMessage}
            />
          ))
        ) : (
          <div> No Open Chats!</div>
        )}
      </div>
    </div>
  );
};

export const SubscribedOpenMessagesPage = () => {
  const auth = useAuth();
  const adminId = auth.userId;
  const reference = useMemo(
    () =>
      query(
        collectionGroup(firestore, "openMessageMetadata"),
        where("isHidden", "==", false),
        where("subscribedUsers", "array-contains", adminId),
        orderBy("oldestMessageTimestamp", "asc"),
        limit(100),
      ),
    [adminId],
  );
  const [openMessages] = useCollectionData(reference, { idField: "id" });

  return (
    <div className="flex-grow p-3 m-3 bg-cardBackgroundColor overflow-y-auto grid content-start gap-2 shadow rounded">
      {openMessages && openMessages.length > 0 ? (
        openMessages.map((openMessage) => (
          <OpenMessageComponent
            key={openMessage.userId}
            openMessageMetadata={openMessage}
          />
        ))
      ) : (
        <div> No Open Chats!</div>
      )}
    </div>
  );
};

export const OpenMessagesPage = () => {
  const [listToShow, setListToShow] = useState(0);

  return (
    <>
      <PageTitle title="Open Messages" />
      <Layout className="flex-grow" titleComponent={ColumnTitle}>
        <LayoutColumn relativeWidth={1} title="Filters">
          <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow text-sm">
            <h2 className="text-lg font-semibold mb-2">Show</h2>
            <div className="pr-10 flex py-1">
              <input
                type="radio"
                style={{}}
                checked={listToShow === 0}
                onChange={() => setListToShow(0)}
              />
              <div className="pl-1">All open chats</div>
            </div>
            <div className="flex py-1">
              <input
                type="radio"
                style={{}}
                checked={listToShow === 1}
                onChange={() => setListToShow(1)}
              />
              <div className="pl-1">Subscribed chats only</div>
            </div>
            <div className="flex py-1">
              <input
                type="radio"
                style={{}}
                checked={listToShow === 2}
                onChange={() => setListToShow(2)}
              />
              <div className="pl-1">Traveling users only</div>
            </div>
          </div>
        </LayoutColumn>
        <LayoutColumn
          relativeWidth={4}
          title="Open chats overview"
          compactTitle="Chats"
        >
          {listToShow === 0 && <AllOpenMessagesPage />}
          {listToShow === 1 && <SubscribedOpenMessagesPage />}
          {listToShow === 2 && <TravelingOpenMessagesPage />}
        </LayoutColumn>
      </Layout>
    </>
  );
};
