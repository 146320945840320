import React, { Suspense, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { faBars as sidebarOpenIcon } from "@fortawesome/free-solid-svg-icons/faBars";
import { faCompass as spinnerIcon } from "@fortawesome/free-solid-svg-icons/faCompass";
import { faEnvelope as emailIcon } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faTimes as sidebarCloseIcon } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { httpsCallable } from "firebase/functions";
import Popup from "reactjs-popup";
import { functions } from "../firebase";
import { ActionButton } from "./ActionButton";
import { LoggedInUser } from "./LoggedInUser";
import { Stats } from "./Stats";
import { UniversalSearch } from "./UniversalSearch";

const Loading = () => (
  <FontAwesomeIcon icon={spinnerIcon} fixedWidth spin size="2x" />
);

export const Navbar = ({ isSidebarOpen, setSidebarOpen }) => {
  const toggleDarkMode = () => {
    const isDarkmode = document.documentElement.classList.contains("darkmode");
    // eslint-disable-next-line no-underscore-dangle
    window.__setPreferredMode(isDarkmode ? "light" : "dark");
  };

  const NewUserPopup = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState("email@domain.com");
    const [displayName, setDisplayName] = useState("First Last");

    const handleOpen = () => {
      setIsOpen(true);
    };

    const handleClose = () => {
      setIsOpen(false);
    };

    const createEmailUser = async () => {
      if (
        !email ||
        email.length < 6 ||
        !email.includes("@") ||
        !email.includes(".")
      ) {
        // eslint-disable-next-line no-alert
        alert("Please enter a proper email");
        return;
      }
      if (!displayName || displayName.length < 1) {
        // eslint-disable-next-line no-alert
        alert("Please enter a proper full name");
        return;
      }
      if (
        // eslint-disable-next-line no-restricted-globals,no-alert
        !confirm(
          `Are you sure you'd like to create an email user with email: ${email} and full name: ${displayName}?`,
        )
      )
        return;

      const result = await httpsCallable(
        functions,
        "processOnboardingCommand",
      )({
        command: "CreateEmailUser",
        params: {
          displayName,
          email,
        },
      });

      setTimeout(() => {
        handleClose();
        navigate(`/users/${result.data}`);
      }, 100);
    };

    return (
      <div>
        <button
          type="button"
          className="text-textColor py-2 rounded  text-sm font-bold"
          onClick={handleOpen}
        >
          <FontAwesomeIcon icon={emailIcon} className="mr-2" />
        </button>
        <Popup
          open={isOpen}
          onClose={handleClose}
          closeOnDocumentClick
          closeOnEscape
          modal
          overlayStyle={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            // backdropFilter: 'blur(4px)',
            // WebkitBackdropFilter: 'blur(4px)',
          }}
          contentStyle={{
            backgroundColor: "var(--cardBackgroundColor)",
            borderWidth: 0,
            borderRadius: "0.5rem",
            maxWidth: "50rem",
            padding: "1rem",
          }}
        >
          <>
            <h1 className="text-2xl">Create Email User</h1>
            <h3 className="text-lg">Email:</h3>
            <input
              className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg px-2 py-1 w-full"
              type="email"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <h3 className="text-lg">Full Name:</h3>
            <input
              className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg px-2 py-1 w-full"
              type="Text"
              value={displayName}
              placeholder="Full Name"
              onChange={(e) => setDisplayName(e.target.value)}
            />
            <ActionButton className="mt-3" onAction={createEmailUser}>
              Create Email User
            </ActionButton>
          </>
        </Popup>
      </div>
    );
  };

  return (
    <div
      className="bg-cardBackgroundColor w-full h-16 z-50 flex items-center shadow-navbar"
      style={{ minHeight: "4rem" }}
    >
      <div className="w-full max-w-screen-xl mx-auto px-3 flex items-center">
        {/* <div className="flex items-center"> */}
        <div className="lg:w-1/5 px-3">
          <Link to="/">
            <div
              style={{ fontFamily: "Futura" }}
              className="text-3xl font-bold"
            >
              Scen<span className="hidden lg:inline">set</span>
            </div>
            {process.env.TAG && (
              <div
                className="ml-1 -mt-3 text-xs font-semibold text-coloredButtonForegroundColor"
                style={{ zIndex: -1 }}
              >
                {process.env.TAG}
              </div>
            )}
          </Link>
        </div>
        <div className="flex flex-grow lg:w-3/5 px-3">
          <Suspense fallback={null}>
            <UniversalSearch />
          </Suspense>
        </div>
        <div className="lg:w-1/5 px-3">
          <button
            type="button"
            className={`lg:hidden focus:text-gray-700 ${isSidebarOpen ? "hidden" : ""}`}
            onClick={() => setSidebarOpen(true)}
          >
            <FontAwesomeIcon icon={sidebarOpenIcon} fixedWidth />
          </button>
          <button
            type="button"
            className={`lg:hidden focus:text-gray-700 ${isSidebarOpen ? "" : "hidden"}`}
            onClick={() => setSidebarOpen(false)}
          >
            <FontAwesomeIcon icon={sidebarCloseIcon} fixedWidth />
          </button>
          <div className="hidden lg:flex lg:justify-end lg:items-center">
            {/* <div className="w-16 flex justify-end"> */}
            <Suspense fallback={<Loading />}>
              <NewUserPopup />
            </Suspense>
            {/* </div> */}
            <button
              type="button"
              className="w-6 h-6 mr-4"
              onClick={toggleDarkMode}
            >
              <div className="toggle" />
            </button>
            <div className="mr-4">
              <Suspense fallback={null}>
                <Stats />
              </Suspense>
            </div>
            {/* <div className="w-16 flex justify-end"> */}
            <Suspense fallback={<Loading />}>
              <LoggedInUser />
            </Suspense>
            {/* </div> */}
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};
