import { ref } from "firebase/storage";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

import { faSpinner as spinnerIcon } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faUpload as uploadIcon } from "@fortawesome/free-solid-svg-icons/faUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPaste as pasteIcon } from '@fortawesome/free-solid-svg-icons/faPaste';

import { storage } from "../firebase";

const EditorIcon = ({ icon, className, ...props }) => {
  return (
    <a
      className={`${
        className || ""
      } w-8 h-8 my-1 bg-mainTintColor text-white rounded-full flex items-center justify-center`}
      {...props}
    >
      <FontAwesomeIcon icon={icon} fixedWidth />
    </a>
  );
};

export const PdfUpload = ({
  onChange,
  aspect,
  className,
  style,
  pdfFolderPath,
  ...props
}) => {
  const [uploadProgress, setUploadProgress] = useState();
  const [isHovering, setHovering] = useState(false);

  const processAndUploadPdf = useCallback(
    (pdfName, pdfBlob) => {
      setUploadProgress(0);

      const storagePath = `${pdfFolderPath || "pdf"}/${uuidv4()}.pdf`;
      const reference = ref(storage, storagePath);
      const uploadTask = reference.put(pdfBlob, {
        contentType: "application/pdf",
      });

      uploadTask.on("state_changed", (snapshot) => {
        setUploadProgress(snapshot.bytesTransferred / snapshot.totalBytes);
      });

      uploadTask
        .then(() => {
          setUploadProgress(undefined);
          return reference.getDownloadURL();
        })
        .then((url) => {
          onChange({ url, name: pdfName });
        })
        .catch((err) => {
          // eslint-disable-next-line no-alert
          alert(err.message);
        });
    },
    [onChange],
  );

  // const onPaste = () => {
  //   navigator.clipboard.readText().then(processAndUploadImage);
  // };

  const onDrop = useCallback(
    (files) => {
      if (!files || files.length === 0) return;
      const [file] = files;
      // I have no idea what I'm doing but it works
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        processAndUploadPdf(file.name || "file.pdf", file),
      );
      reader.readAsDataURL(file);
    },
    [processAndUploadPdf],
  );

  const isUploading = uploadProgress !== undefined;

  const { getRootProps, getInputProps, /* open: openUpload, */ isDragActive } =
    useDropzone({
      accept: "application/pdf",
      multiple: false,
      disabled: isUploading,
      onDrop,
      // noClick: true,
      noKeyboard: true,
    });

  return (
    <button
      {...getRootProps({
        className: `${
          className || ""
        } relative border border-dividerColor rounded-lg ${
          aspect ? "bg-cover" : "bg-contain p-2"
        } bg-center bg-no-repeat`,
        disabled: isUploading,
        style: {
          ...style,
          ...(!aspect && { backgroundOrigin: "content-box" }),
        },
        onMouseEnter: () => setHovering(!isDragActive),
        onMouseLeave: () => setHovering(false),
        ...props,
      })}
    >
      <div
        style={{
          paddingTop: `${(100 / (aspect || 1)).toFixed()}%`,
          height: 0,
        }}
      >
        <input {...getInputProps()} />
        {(() => {
          if (isUploading || isDragActive) {
            return (
              <div className="absolute left-0 right-0 top-0 bottom-0 bg-textInverseSecondaryColor flex flex-col justify-center items-center">
                {isUploading ? (
                  <FontAwesomeIcon icon={spinnerIcon} spin fixedWidth />
                ) : (
                  <FontAwesomeIcon icon={uploadIcon} fixedWidth />
                )}
                <div className="font-bold">
                  {isUploading ? "Uploading" : "Drop to upload"}
                </div>
                {isUploading && (
                  <div className="italic">
                    {Math.floor(uploadProgress * 100)}%
                  </div>
                )}
              </div>
            );
          }
          if (isHovering) {
            return (
              <div className="absolute left-0 right-0 top-0 bottom-0 bg-textInverseSecondaryColor flex flex-col justify-center items-center">
                {/* <EditorIcon icon={pasteIcon} onClick={onPaste} /> */}
                <EditorIcon icon={uploadIcon} /* onClick={openUpload} */ />
              </div>
            );
          }
          return (
            <div className="absolute left-0 right-0 top-0 bottom-0 bg-textInverseSecondaryColor text-dividerColor flex flex-col justify-center items-center">
              <FontAwesomeIcon icon={uploadIcon} fixedWidth />
            </div>
          );
        })()}
        {/* {(isUploading || isDragActive) && (
          <div className="absolute left-0 right-0 top-0 bottom-0 bg-textInverseSecondaryColor flex flex-col justify-center items-center">
            {isUploading ? (
              <FontAwesomeIcon icon={spinnerIcon} spin fixedWidth />
            ) : (
              <FontAwesomeIcon icon={uploadIcon} fixedWidth />
            )}
            <div className="font-bold">{isUploading ? 'Uploading' : 'Drop to upload'}</div>
            {isUploading && <div className="italic">{Math.floor(uploadProgress * 100)}%</div>}
          </div>
        )}
        {!(isUploading || isDragActive) && isHovering && (
          <div className="absolute left-0 right-0 top-0 bottom-0 bg-textInverseSecondaryColor flex flex-col justify-center items-center">
            // <EditorIcon icon={pasteIcon} onClick={onPaste} />
            <EditorIcon icon={uploadIcon} onClick={openUpload} />
          </div>
        )} */}
      </div>
    </button>
  );
};
