import React, { useMemo, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useNavigate, useParams } from "react-router-dom";

import { collection, orderBy, query } from "firebase/firestore";
import { firestore } from "../../firebase";

import { ColumnTitle } from "../ColumnTitle";
import { Layout, LayoutColumn } from "../Layout";
import { PageTitle } from "../PageTitle";

import { SupplierPaymentListing } from "./SupplierPaymentListing";
import { SupplierPaymentPageItemInfo } from "./SupplierPaymentPageItemInfo";

export const SupplierPaymentsPage = () => {
  const [showOpenOnly, setShowOpenOnly] = useState(false);
  const reference = useMemo(
    () =>
      query(
        collection(firestore, "supplierPayments"),
        orderBy("createdAt", "desc"),
      ),
    [],
  );
  const [allSupplierPayments] = useCollectionData(reference, { idField: "id" });
  const navigate = useNavigate();
  const { paymentId } = useParams();
  const selectedPayment =
    paymentId &&
    allSupplierPayments &&
    allSupplierPayments.find(({ id }) => id === paymentId);
  const supplierPayments =
    allSupplierPayments && showOpenOnly
      ? allSupplierPayments.filter(({ status }) => status === "OPEN")
      : allSupplierPayments;

  const onSelectPaymentId = (selectedInspirationId) => {
    navigate(
      `/supplierPayments${
        selectedInspirationId ? `/${selectedInspirationId}` : ""
      }`,
      { replace: true },
    );
  };

  return (
    <>
      <PageTitle
        title={selectedPayment ? selectedPayment.title : "Supplier Payments"}
      />
      <Layout className="flex-grow" titleComponent={ColumnTitle}>
        <LayoutColumn
          relativeWidth={2}
          title={
            <div className="flex items-center">
              <div className="flex-grow">All Supplier Payments</div>
              <div className={`font-sans font-normal flex items-center`}>
                <input
                  className="w-0 h-0"
                  style={{ visibility: "hidden" }}
                  id="toggleOpenOnly"
                  type="checkbox"
                  checked={showOpenOnly}
                  onChange={() => setShowOpenOnly(!showOpenOnly)}
                />
                <label
                  className={`flex items-center transition-colors duration-100 ease-out ${
                    showOpenOnly
                      ? "bg-coloredButtonForegroundColor"
                      : "bg-dividerColor"
                  } w-12 h-6 rounded-full cursor-pointer`}
                  htmlFor="toggleOpenOnly"
                >
                  <span
                    className="bg-cardBackgroundColor h-0 w-0 rounded-full transition-transform duration-100 ease-out"
                    style={{
                      paddingTop: "42%",
                      paddingLeft: "42%",
                      marginLeft: "4%",
                      marginRight: "4%",
                      ...(showOpenOnly && { transform: "translate(119%, 0)" }),
                    }}
                  />
                </label>
                <label
                  htmlFor="toggleOpenOnly"
                  className="text-sm ml-1 leading-none"
                >
                  Show Open Payments only
                </label>
              </div>
            </div>
          }
          compactTitle="Inspirations"
        >
          <SupplierPaymentListing
            supplierPayments={supplierPayments || []}
            selectedSupplierPaymentId={paymentId}
            onSelectPaymentId={onSelectPaymentId}
          />
        </LayoutColumn>
        <LayoutColumn
          relativeWidth={1}
          title="Inspiration Info"
          compactTitle="Info"
        >
          <SupplierPaymentPageItemInfo selectedPayment={selectedPayment} />
        </LayoutColumn>
      </Layout>
    </>
  );
};
